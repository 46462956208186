import TipoVisibilidadePesquisaEnum from 'enums/TipoVisibilidadePesquisaEnum';
import RolesUsuariosSistemaEnum from 'enums/RolesUsuariosSistemaEnum';

interface UserData {
  admin: boolean;
  auth_id: number;
  email: string;
  exibePerguntaSentimento: boolean;
  exibePerguntaEscalaComLegenda?: boolean;
  aumentaCaracteresBateria?: boolean;
  exp: number;
  iat: number;
  id: number;
  is_dono: boolean;
  nome: string;
  nome_organizacao: string;
  organizacao_id: number;
  role_id: number;
  sistema_id: number;
}
export function verificaUsuarioRestrito(
  user: UserData | Record<string, undefined>,
  visibilidade: TipoVisibilidadePesquisaEnum,
  usuarios_visualizacao_restrita: number[] | null,
): boolean {
  if (!user || !user.auth_id) {
    throw new Error('Usuário não autenticado ou inválido.');
  }

  if (user.is_dono) {
    return true;
  }

  switch (visibilidade) {
    case TipoVisibilidadePesquisaEnum.ADMINISTRADORES:
      return user.role_id === RolesUsuariosSistemaEnum.ADMIN_ORGANIZACAO;

    case TipoVisibilidadePesquisaEnum.SOMENTE_EU:
      return false;

    case TipoVisibilidadePesquisaEnum.TODOS_EXCETO:
      return !usuarios_visualizacao_restrita?.includes(user.auth_id);

    case TipoVisibilidadePesquisaEnum.TODOS:
      return true;

    default:
      return false;
  }
}
