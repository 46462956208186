import React, { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getRoleUsuarioAutenticado } from 'config/Roles';
import LocalStorageEnum from 'enums/LocalStorageEnum';
import { usePesquisa, useUserData } from 'hooks';
import { verificaUsuarioRestrito } from 'pages/Pesquisas/utils/verificaUsuarioRestrito';

type PrivateRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactNode | any;
  permission?: string[];
  verificacaoAcessoRestrito?: boolean;
  verificacaoPesquisaExistente?: boolean;
};

const isAuth = () => {
  if (
    localStorage.getItem(LocalStorageEnum.TOKEN) === '' ||
    localStorage.getItem(LocalStorageEnum.TOKEN) === null
  ) {
    return false;
  }

  return true;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  permission,
  verificacaoAcessoRestrito,
  verificacaoPesquisaExistente,
}) => {
  const roleUsuarioAutenticado = getRoleUsuarioAutenticado();

  const usuarioAutenticado = isAuth();

  const pesquisa = usePesquisa();
  const user = useUserData();

  if (!usuarioAutenticado || !user) {
    return <Navigate to="/login" replace />;
  }

  if (
    permission &&
    roleUsuarioAutenticado &&
    !permission.includes(roleUsuarioAutenticado)
  ) {
    return <Navigate to="/login" replace />;
  }

  if (
    verificacaoPesquisaExistente &&
    (!pesquisa || pesquisa.organizacao_id !== user.organizacao_id)
  ) {
    return <Navigate to="/pesquisa-nao-encontrada" replace />;
  }

  if (
    verificacaoAcessoRestrito &&
    pesquisa &&
    user &&
    !verificaUsuarioRestrito(
      user,
      pesquisa.visibilidade,
      pesquisa.usuarios_visualizacao_restrita,
    )
  ) {
    return <Navigate to="/acesso-restrito" replace />;
  }

  return children;
};

export function PrivateRouteOutlet(): JSX.Element {
  return isAuth() ? <Outlet /> : <Navigate to="/login" replace />;
}
