import React from 'react';
import { CustomInput, CustomInputProps } from 'reactstrap';

import '../input.css';

type InputCheckboxAlternativoProps = CustomInputProps;

const InputCheckboxAlternativo: React.FC<InputCheckboxAlternativoProps> = ({
  id = 'custom-checkbox',
  label = '',
  value,
  disabledWithClick = false,
  disabled,
  ...rest
}) => {
  return (
    <CustomInput
      {...rest}
      id={id}
      label={label}
      value={value}
      type="checkbox"
      checked={!!value}
      disabled={disabled}
      className={`${disabled ? 'disabled' : ''} ${
        disabledWithClick ? 'disabled-with-click' : ''
      }`.trim()}
    />
  );
};

export default InputCheckboxAlternativo;
