import LocalStorageEnum from 'enums/LocalStorageEnum';
import jwt, { JwtPayload } from 'jsonwebtoken';

export const getUserData = (): Record<string, unknown> => {
  const tokenStorage = localStorage.getItem(LocalStorageEnum.TOKEN);
  const payloadStorage = localStorage.getItem(LocalStorageEnum.PAYLOAD);
  let userData = {};

  if (tokenStorage) {
    const jsonPayload = jwt.decode(
      tokenStorage.replace(/bearer\s/gi, ''),
    ) as JwtPayload;
    userData = { ...userData, ...jsonPayload };
  }

  if (payloadStorage) {
    const payload = JSON.parse(payloadStorage);
    userData = { ...userData, ...payload };
  }

  return userData;
};
