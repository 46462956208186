import {
  createColumnHelper,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  useReactTable,
} from '@tanstack/react-table';
import InputCheckBox from 'components/Inputs/InputCheckboxAlternativo';
import { Fragment, useMemo, useState } from 'react';
import { Col, ColProps, ListGroupItem, Row } from 'reactstrap';
import CategorizacaoScreenContainer from '../../components/CategorizacaoScreenContainer';

import { TableHeader } from '../../components/TableHeader';
import { TagSelector } from '../../components/TagSelector';

import styles from './styles.module.scss';

export type Categoria = {
  id: number;
  nome: string;
  descricao: string;
};

const columnHelper = createColumnHelper<Categoria>();
interface SelecionarCategoriasProps {
  categorias: Categoria[];
  categoriasSelecionadas: Record<string, boolean>;
  onCategoriasSelection: (categorias: Record<string | number, boolean>) => void;
}

function getColumnHeaderProps<T>(header: Header<T, unknown>) {
  const props: Record<string, ColProps> = {
    categoria: {
      md: 4,
    },
    descricao: {
      md: 8,
    },
  };

  return props[header.id as string];
}

function SelecionarCategoriasPage({
  categorias,
  categoriasSelecionadas,
  onCategoriasSelection,
}: SelecionarCategoriasProps) {
  const tableColumns = useMemo(
    () => [
      columnHelper.accessor(({ nome }) => nome, {
        id: 'categoria',
        header: 'Categoria',
        cell: ({ row }) => {
          return (
            <Col md="4">
              <div className={styles.selectableRowContainer}>
                <InputCheckBox
                  {...{
                    id: row.id,
                    type: 'checkbox',
                    checked: row.getIsSelected(),
                    value: row.getIsSelected() ? 1 : 0,
                    disabled: !row.getCanSelect(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
                <b className="text-primary">{row.original.nome}</b>
              </div>
            </Col>
          );
        },
      }),
      columnHelper.accessor(({ descricao }) => descricao, {
        id: 'descricao',
        header: 'Descrição',
        cell: ({ row }) => {
          return (
            <Col md="8" className={styles.rowDescription}>
              <p>{row.original.descricao}</p>
            </Col>
          );
        },
      }),
    ],
    [],
  );

  const [sorting, setSorting] = useState([{ id: 'categoria', desc: false }]);

  const tableCategorias = useReactTable({
    data: categorias,
    columns: tableColumns,
    state: {
      sorting,
      rowSelection: categoriasSelecionadas,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (originalRow) => originalRow.id.toString(),
    onSortingChange: setSorting,
    onRowSelectionChange: (updaterFn) => {
      const newValue = functionalUpdate(updaterFn, categoriasSelecionadas);
      onCategoriasSelection(newValue);
    },
    enableSortingRemoval: false,
    sortDescFirst: false,
    enableRowSelection: ({ id }) => {
      const limiteSelecao = 15;

      const qtdPerguntasSelecionadas = Object.keys(
        categoriasSelecionadas,
      ).length;

      if (qtdPerguntasSelecionadas < limiteSelecao) {
        return true;
      }
      if (qtdPerguntasSelecionadas >= limiteSelecao) {
        return id in categoriasSelecionadas;
      }

      return false;
    },
  });

  const tableCategoriasRows = tableCategorias.getRowModel().rows;

  return (
    <>
      <CategorizacaoScreenContainer
        titulo="Análise de respostas abertas com IA"
        descricao="Selecione abaixo as categorias que servirão como base para a análise e categorização das respostas."
      >
        <TagSelector
          selectedItems={categoriasSelecionadas}
          tags={categorias}
          onRemove={(payload) => onCategoriasSelection(payload)}
        />
        <Row tag="table" className="pt-3">
          <Col md="12" tag="thead">
            <TableHeader
              headerGroup={tableCategorias.getHeaderGroups()[0]}
              getColProps={getColumnHeaderProps}
            />
          </Col>

          <Col md="12" tag="tbody">
            {tableCategoriasRows.map((row) => (
              <ListGroupItem
                key={row.id}
                tag="tr"
                className={`${styles.tableRow} ${
                  row.getIsSelected() ? styles.selectedRow : ''
                }`}
              >
                <Row tag="td" className="align-items-center">
                  {row.getVisibleCells().map((cell) => (
                    <Fragment key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Fragment>
                  ))}
                </Row>
              </ListGroupItem>
            ))}
          </Col>
        </Row>
      </CategorizacaoScreenContainer>
    </>
  );
}

export default SelecionarCategoriasPage;
