import instance from './instance';
import { Pesquisa } from '../../entities/pesquisa/pesquisa';

interface GetCategoriasProps {
  filtros: {
    status?: {
      id: number;
      type: string;
    };
    sorting_pesquisa?: {
      id: string;
      type: string;
      desc: boolean;
    };
    nome_pesquisa?: {
      type: string;
      nome: string;
    };
  };
  paginaTratativa: number;
}

export interface PesquisasComNumeroCategorias extends Pesquisa {
  categorias_utilizadas: number;
  recategorizada: boolean;
  usuario_logado_bloqueado: boolean;
}

interface GetCategoriasResult {
  pesquisas: PesquisasComNumeroCategorias[];
  qtd_paginas: number;
  current_page: number;
}

export const getPesquisasCategorizaçao = async ({
  filtros,
  paginaTratativa,
}: GetCategoriasProps): Promise<GetCategoriasResult> => {
  const result = await instance.post('/pesquisas', {
    filtros,
    paginaTratativa,
  });

  return result.data;
};
