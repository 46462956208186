import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';
import { Usuario } from '../../entities/auth/usuario';

export interface IUsuario extends Usuario {
  admin: boolean;
  data_alteracao?: Date;
  data_criacao: Date;
  login: string;
  role_descricao: string;
  ativo_sistema: boolean;
  email: string;
}

export interface IListaUsuariosPayload {
  data: {
    success: boolean;
    users: IUsuario[];
  };
}

const buscaUsuariosRestringirVisibilidade =
  (): Promise<IListaUsuariosPayload> => {
    const token = localStorage.getItem(LocalStorageEnum.TOKEN);
    if (!token) {
      throw new Error('Token não existe');
    }

    return instance.get('/lista-usuarios-organizacao/', {
      headers: {
        Authorization: token.toString(),
      },
    });
  };

export default buscaUsuariosRestringirVisibilidade;
