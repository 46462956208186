import LocalStorageEnum from 'enums/LocalStorageEnum';
import instance from './instance';
import { Usuario } from '../../entities/auth/usuario';

export interface IUsuario extends Usuario {
  admin: boolean;
  data_alteracao?: Date;
  data_criacao: Date;
  login: string;
  role_descricao: string;
  ativo_sistema: boolean;
}

interface IListaUsuariosPayload {
  data: {
    success: boolean;
    users: IUsuario[];
  };
}

const buscaListaUsuarios = (): Promise<IListaUsuariosPayload> => {
  const token = localStorage.getItem(LocalStorageEnum.TOKEN);
  if (!token) {
    throw new Error('Token não existe');
  }

  return instance.get('/lista-usuarios/', {
    headers: {
      Authorization: token.toString(),
    },
  });
};

export default buscaListaUsuarios;
