enum LocalStorageEnum {
  VENCIMENTO_TRIAL = 'vencimento_trial',
  TOKEN = 'token',
  PAYLOAD = 'payload',
  TAG_VENCIMENTO_TRIAL = 'tag_vencimento_trial',
  ORGANIZACOES = 'organizacoes',
  AVISO_NOVOS_CANAIS = 'primeiroLoginLinkQrcodeTotem',
  AVISO_DUPLICAR_PESQUISA = 'primeiroLoginDuplicarPesquisa',
  AVISO_VISIBILIDADE_PESQUISAS = 'primeiroLoginVisibilidadePesquisas',
}

export default LocalStorageEnum;
