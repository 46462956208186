import instance from './instance';
import { PesquisaObject } from './types';

interface GetPesquisasData {
  success: boolean;
  result: { pesquisas: PesquisaObject[] };
}

interface GetPesquisasPayload {
  data: GetPesquisasData;
}

const getAll = (): Promise<GetPesquisasPayload> => instance.get('/');

export default getAll;
