import React from 'react';

import { permissaoTodosUsuariosExcetoVisualizador } from 'config/Roles';

import { FormProvider } from 'contexts/FormContext';

import Loader from 'components/Loader';
import CategorizacaoIA from 'pages/Pesquisas/Configuracao/CategorizacaoIA';
import { PrivateRoute } from './PrivateRoute';

const IntervaloEnvioApi = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Sms/IntervaloEnvioApi" */ 'pages/Pesquisas/Configuracao/EnvioAPI/Email/IntervaloEnvioApi'
    ),
);

const IntervaloEnvioSmsApi = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Sms/IntervaloEnvioApi" */ 'pages/Pesquisas/Configuracao/EnvioAPI/Sms/IntervaloEnvioApi'
    ),
);

const CriarQuestionario = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CriarQuestionario" */ 'pages/Pesquisas/CriarQuestionario'
    ),
);

const PesquisaEnviada = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PesquisaEnviada" */ 'pages/Pesquisas/PesquisaEnviada'
    ),
);

const EmailConfig = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Convite/Email" */ 'pages/Pesquisas/Configuracao/Convite/Email'
    ),
);

const SmsConfigConvite = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Convite/Sms" */ 'pages/Pesquisas/Configuracao/Convite/Sms'
    ),
);

const SmsConfigManual = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Configuracao/Sms/Manual" */ 'pages/Pesquisas/Configuracao/Sms/Manual'
    ),
);

const SmsConfigApi = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Configuracao/Sms/Api" */ 'pages/Pesquisas/Configuracao/Sms/Api'
    ),
);

const SmsConfgLembrete = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Lembrete/Sms" */ 'pages/Pesquisas/Configuracao/Lembrete/Sms'
    ),
);

const Envios = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Envios/Email" */ 'pages/Pesquisas/Envios/Email'
    ),
);

const EnviosSms = React.lazy(
  () =>
    import(/* webpackChunkName: "Envios/Sms" */ 'pages/Pesquisas/Envios/Sms'),
);

const EnvioAPI = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EnvioAPI/Email" */ 'pages/Pesquisas/Configuracao/EnvioAPI/Email'
    ),
);

const FinalizarEnvioApi = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EnvioAPI/Email/FinalizarEnvioApi" */ 'pages/Pesquisas/Configuracao/EnvioAPI/Email/FinalizarEnvioApi'
    ),
);

const Traducao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Idioma/Traducao" */ 'pages/Pesquisas/Idioma/Traducao'
    ),
);

const Pesquisas = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Pesquisas'
    ),
);

const CriarPesquisa = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Configuracao" */ 'pages/Pesquisas/CriacaoPesquisa/CriarPesquisa'
    ),
);
const DuplicarPesquisa = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Configuracao" */ 'pages/Pesquisas/CriacaoPesquisa/DuplicarPesquisa'
    ),
);
const Configuracao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Configuracao" */ 'pages/Pesquisas/Configuracao'
    ),
);

const ConfiguracaoEnvio = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracaoEnvio" */ 'pages/Pesquisas/ConfiguracaoEnvio'
    ),
);

const ConfiguracaoLimiteResposta = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracaoEnvio" */ 'pages/Pesquisas/LimiteResposta'
    ),
);

const PesquisaLinkFinalizada = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracaoEnvio" */ 'pages/Pesquisas/PesquisaLinkFinalizada'
    ),
);

const ListaEnvio = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ListaEnvio" */ 'pages/Pesquisas/ListaEnvio/ListaEnvio'
    ),
);

const ListaEnvioSms = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ListaEnvio/Sms" */ 'pages/Pesquisas/ListaEnvio/Sms/ListaEnvioSms'
    ),
);

const EditarQuestionario = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EditarQuestionario" */ 'pages/Pesquisas/EditarQuestionario'
    ),
);

const PaginaAgradecimento = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EditarQuestionario" */ 'pages/Pesquisas/PaginaAgradecimento'
    ),
);

const PaginaInicialTotem = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EditarQuestionario" */ 'pages/Pesquisas/PaginaInicialTotem'
    ),
);

const VisualizarResultado = React.lazy(
  () =>
    import(
      /* webpackChunkName: "VisualizarResultado" */ 'pages/VisualizarResultado'
    ),
);

const ListaContatosEnvio = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Email/ListaContatosEnvio" */ 'pages/Pesquisas/Envios/Email/ListaContatosEnvio'
    ),
);

const ListaContatosEnvioSms = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Sms/ListaContatosEnvio" */ 'pages/Pesquisas/Envios/Sms/ListaContatosEnvio'
    ),
);

const ListagemIdiomas = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Idioma/Listagem" */ 'pages/Pesquisas/Idioma/Listagem'
    ),
);

const EstatisticaEnvio = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EstatisticaEnvio" */ 'pages/EstatisticaEnvio/index'
    ),
);

const EstatisticaEnvioSms = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EstatisticaEnvioSms" */ 'pages/EstatisticaEnvioSms/index'
    ),
);

const Lembrete = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Lembrete/Email" */ 'pages/Pesquisas/Configuracao/Lembrete/Email'
    ),
);
const Quarentena = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Lembrete/Email" */ 'pages/Pesquisas/Configuracao/Quarentena'
    ),
);

const WidgetConfig = React.lazy(
  () => import(/* webpackChunkName: "Widget" */ 'pages/Widget'),
);

const ConfiguracaoWidget = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfiguracaoWidget" */ 'pages/Widget/ConfiguracaoWidget'
    ),
);

const InstrucoesIntegracao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InstrucoesIntegracao" */ 'pages/Widget/InstrucoesIntegracao'
    ),
);

const EstatisticaWidget = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EstatisticaEnvio" */ 'pages/Widget/EstatisticaEnvio'
    ),
);

const PersonalizarQrcode = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PersonalizarQrcode" */ 'pages/Pesquisas/Qrcode/screens/PersonalizarQrcode'
    ),
);

const EstatisticaLink = React.lazy(
  () =>
    import(/* webpackChunkName: "EstatisticaLink" */ 'pages/EstatisticaLink'),
);

export const rotasPesquisa = (HomeLayout: React.FC) => {
  return {
    path: '/pesquisa',
    element: (
      <PrivateRoute>
        <React.Suspense fallback={<Loader />}>
          <HomeLayout />
        </React.Suspense>
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: <Pesquisas />,
      },
      {
        path: 'criar-pesquisa/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <CriarPesquisa />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/duplicar-pesquisa/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <DuplicarPesquisa />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/configuracao/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <Configuracao />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/:envioId/configuracao/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ConfiguracaoEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/configuracao/categorizacao',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            {' '}
            <CategorizacaoIA />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/configuracao/categorizacao/editar',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <CategorizacaoIA />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/:envioId/configuracao/api',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <EnvioAPI />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/:envioId/configuracao/api/editar',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <EnvioAPI />
          </PrivateRoute>
        ),
      },
      ...[':pesquisaId/link-externo/', ':pesquisaId/totem/'].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <Configuracao />
            </PrivateRoute>
          ),
        };
      }),
      {
        path: ':pesquisaId/link-externo/configuracao/limite-resposta/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <FormProvider>
              <ConfiguracaoLimiteResposta />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/totem/configuracao/limite-resposta/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <FormProvider>
              <ConfiguracaoLimiteResposta />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/link-externo/finalizar',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <PesquisaLinkFinalizada />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/totem/finalizar',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <PesquisaLinkFinalizada />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/qrcode/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <Configuracao />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/qrcode/configuracao/limite-resposta/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <FormProvider>
              <ConfiguracaoLimiteResposta />
            </FormProvider>
          </PrivateRoute>
        ),
      },

      ...[
        ':pesquisaId/configuracao/api/finalizar',
        ':pesquisaId/envio/:envioId/configuracao/api/finalizar',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <FinalizarEnvioApi />
            </PrivateRoute>
          ),
        };
      }),
      ...[
        ':pesquisaId/questionario/editar',
        ':pesquisaId/envio/:envioId/questionario/editar',
        ':pesquisaId/sms/questionario/editar/envio/:envioId',
        ':pesquisaId/questionario/editar/:tipoEdicao',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <FormProvider>
                <EditarQuestionario />
              </FormProvider>
            </PrivateRoute>
          ),
        };
      }),
      ...[
        ':pesquisaId/pagina-agradecimento',
        ':pesquisaId/pagina-agradecimento/:tipoEdicao',
        ':pesquisaId/envio/:envioId/pagina-agradecimento',
        ':pesquisaId/envio/:envioId/pagina-agradecimento/:tipoEdicao',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <FormProvider>
                <PaginaAgradecimento />
              </FormProvider>
            </PrivateRoute>
          ),
        };
      }),
      ...[
        ':pesquisaId/pagina-inicial',
        ':pesquisaId/pagina-inicial/:tipoEdicao',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <FormProvider>
                <PaginaInicialTotem />
              </FormProvider>
            </PrivateRoute>
          ),
        };
      }),

      {
        path: 'resultado/visualizar/:pesquisaId',
        element: (
          <PrivateRoute verificacaoAcessoRestrito verificacaoPesquisaExistente>
            <VisualizarResultado />
          </PrivateRoute>
        ),
      },
      {
        path: '/pesquisa/:pesquisaId/envio/:envioId/estatisticas/:tipoPesquisa/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <EstatisticaEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: '/pesquisa/:pesquisaId/sms/envio/:envioId/estatisticas/:tipoPesquisa/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <EstatisticaEnvioSms />
          </PrivateRoute>
        ),
      },
      {
        path: '/pesquisa/:pesquisaId/sms/estatisticas/:tipoPesquisa/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <EstatisticaEnvioSms />
          </PrivateRoute>
        ),
      },
      /* {
        path: '/pesquisa/:pesquisaId/link-externo/estatistica/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <EstatisticaLink />
          </PrivateRoute>
        ),
      }, */
      ...[
        '/pesquisa/:pesquisaId/link-externo/estatisticas/',
        '/pesquisa/:pesquisaId/qrcode/estatisticas/',
        '/pesquisa/:pesquisaId/totem/estatisticas/',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <EstatisticaLink />
            </PrivateRoute>
          ),
        };
      }),
      ...[
        ':pesquisaId/sms/questionario/criar/envio/:envioId',
        ':pesquisaId/questionario/criar',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <CriarQuestionario />
            </PrivateRoute>
          ),
        };
      }),
      {
        path: ':pesquisaId/envio/:envioId/configuracao/api/intervalo',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <IntervaloEnvioApi />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/sms/configuracao/api/intervalo/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <FormProvider>
              <IntervaloEnvioSmsApi />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <Envios />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/sms/envio/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <EnviosSms />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/configuracao/publico/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaEnvio primeiroEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/sms/configuracao/publico/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaEnvioSms novoEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/:envioId/configuracao/publico/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/sms/configuracao/envio/:envioId/publico/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaEnvioSms primeiroEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/configuracao/publico/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaEnvio novoEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/sms/envio/configuracao/publico/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaEnvioSms novoEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: 'enviada',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <PesquisaEnviada />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/configuracao/lembrete/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <Lembrete />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/:envioId/configuracao/lembrete/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <Lembrete />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/configuracao/quarentena/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <Quarentena />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/:envioId/configuracao/quarentena/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <Quarentena />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/envio/:envioId/listas/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaContatosEnvio />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/sms/envio/:envioId/listas/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ListaContatosEnvioSms />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/widget/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <ConfiguracaoWidget />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/widget/integracao/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <InstrucoesIntegracao />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/widget/estatisticas/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <EstatisticaWidget />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/widget/personalizar/',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <FormProvider>
              <WidgetConfig />
            </FormProvider>
          </PrivateRoute>
        ),
      },
      ...[
        ':pesquisaId/configuracao/email/',
        ':pesquisaId/configuracao/envio/:envioId/email',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <EmailConfig />
            </PrivateRoute>
          ),
        };
      }),
      ...[
        ':pesquisaId/sms/configuracao/convite',
        ':pesquisaId/sms/configuracao/envio/:envioId/convite',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <SmsConfigConvite />
            </PrivateRoute>
          ),
        };
      }),
      {
        path: ':pesquisaId/sms/configuracao',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <SmsConfigApi />
          </PrivateRoute>
        ),
      },
      {
        path: ':pesquisaId/sms/configuracao/envio/:envioId',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <SmsConfigManual />
          </PrivateRoute>
        ),
      },
      ...[
        ':pesquisaId/sms/configuracao/envio/:envioId/lembrete/',
        ':pesquisaId/sms/configuracao/lembrete/',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <SmsConfgLembrete />
            </PrivateRoute>
          ),
        };
      }),
      ...[
        ':pesquisaId/envio/:envioId/idioma/',
        ':pesquisaId/idioma/',
        ':pesquisaId/idioma/:tipoEdicao',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <ListagemIdiomas />
            </PrivateRoute>
          ),
        };
      }),
      ...[
        ':pesquisaId/envio/:envioId/idioma/:idiomaId/:etapa',
        ':pesquisaId/idioma/:idiomaId/:etapa',
        ':pesquisaId/idioma/:idiomaId/:etapa/:tipoEdicao',
      ].map((path) => {
        return {
          path,
          element: (
            <PrivateRoute
              permission={permissaoTodosUsuariosExcetoVisualizador}
              verificacaoAcessoRestrito
              verificacaoPesquisaExistente
            >
              <Traducao />
            </PrivateRoute>
          ),
        };
      }),
      {
        path: ':pesquisaId/personalizar/qrcode',
        element: (
          <PrivateRoute
            permission={permissaoTodosUsuariosExcetoVisualizador}
            verificacaoAcessoRestrito
            verificacaoPesquisaExistente
          >
            <PersonalizarQrcode />
          </PrivateRoute>
        ),
      },
    ],
  };
};
