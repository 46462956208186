import ReactDOM from 'react-dom/client';

import AppRouter from 'routes/routes';
// import { configureStore } from '@reduxjs/toolkit'

import './assets/scss/bootstrap-custom.scss';

import LocalStorageEnum from 'enums/LocalStorageEnum';
import { getAll } from './services/pesquisa';
import { buscaListas } from './services/contato';
import configureStore from './store/configureStore';

import {
  getRoleUsuarioAutenticado,
  permissaoTodosUsuariosExcetoVisualizador,
} from './config/Roles';

// import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
// import './assets/App.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

function render(preloadedState = {}) {
  // const store = configureStore(preloadedState);
  const store = configureStore(preloadedState);

  root.render(<AppRouter store={store} />);
}
// render();

if (!localStorage.getItem(LocalStorageEnum.TOKEN)) {
  render();
} else {
  getAll()
    .then((pesquisa) => pesquisa.data.result)
    .then(async (pesquisaItens) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let contatos: any = [];

      const roleUsuarioAutenticado = getRoleUsuarioAutenticado();

      if (
        roleUsuarioAutenticado &&
        permissaoTodosUsuariosExcetoVisualizador.includes(
          roleUsuarioAutenticado,
        )
      ) {
        contatos = await buscaListas();
      }
      render({
        pesquisa: {
          items: [...pesquisaItens.pesquisas],
          loading: false,
          error: null,
        },
        contato: {
          items: contatos?.data?.result ? contatos.data.result : [],
          loading: false,
          error: null,
        },
      });
    })
    .catch((erro) => {
      console.log(erro);
      localStorage.removeItem(LocalStorageEnum.TOKEN);
      render();
    });
}
