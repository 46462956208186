import instance from './instance';

interface IBuscaUsuariosVisualizacaoRestrita {
  data: {
    success: boolean;
    usuariosRestritos: number[];
  };
}

const buscaUsuariosVisualizacaoRestrita = (
  pesquisaId: number,
): Promise<IBuscaUsuariosVisualizacaoRestrita> =>
  instance.get(`/restricao-visualizacao/${pesquisaId}`);

export default buscaUsuariosVisualizacaoRestrita;
