import { Fragment, useMemo, useRef, useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  HeaderGroup,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import type { Row as TRow } from '@tanstack/react-table';

import { Pergunta } from 'hooks/types';
import Button from 'components/Button';
import Modal, { ModalRef } from 'components/Modal';
import InputCheckBox from 'components/Inputs/InputCheckboxAlternativo';

import { Col, ListGroupItem, Row, ModalBody, ModalFooter } from 'reactstrap';
import { ColProps } from 'reactstrap/lib';
import { removeTagsFromString } from 'utils/string';
import CategorizacaoScreenContainer from '../../components/CategorizacaoScreenContainer';

import { TableHeader } from '../../components/TableHeader';

import styles from './styles.module.scss';

interface SelecionarPerguntasProps {
  perguntas: Pergunta[];
  perguntasSelecionadas: Record<string, boolean>;
  onPerguntasSelection: (perguntas: Record<string | number, boolean>) => void;
}

const columnHelper = createColumnHelper<Pergunta>();

export interface TableHeaderGroup<T> {
  headerGroup: HeaderGroup<T>;
}

function getColumnHeaderProps<T>(header: Header<T, unknown>) {
  const props: Record<string, ColProps> = {
    titulo: {
      md: 12,
    },
  };

  return props[header.id as string];
}

type CheckboxCellProps<T> = {
  row: TRow<T>;
};

function CheckboxCell<T extends Pergunta>({ row }: CheckboxCellProps<T>) {
  const modalRef = useRef<ModalRef>(null);

  function handleChange() {
    if (row.getIsSelected()) {
      modalRef.current?.openModal?.();
    } else {
      row.toggleSelected(true);
    }
  }

  function handleConfirmRemoval() {
    modalRef.current?.closeModal?.();
    row.toggleSelected(false);
  }

  return (
    <Col md="12">
      <div className={styles.selectableRowContainer}>
        <InputCheckBox
          {...{
            id: row.id,
            type: 'checkbox',
            checked: row.getIsSelected(),
            value: row.getIsSelected() ? 1 : 0,
            disabled: !row.getCanSelect(),
            onChange: handleChange,
          }}
        />

        <b className="text-primary">
          {removeTagsFromString(row.original.titulo)}
        </b>
      </div>

      <Modal
        ref={modalRef}
        centered
        className={styles.modalContainer}
        title="Excluir categorização"
      >
        <ModalBody>
          <p>
            Ao desmarcar esta pergunta as respostas atribuídas à ela não serão
            mais categorizadas. Deseja continuar?
          </p>
        </ModalBody>

        <ModalFooter>
          <Button outline onClick={() => modalRef.current?.closeModal?.()}>
            Cancelar
          </Button>

          <Button className="px-3 btn-danger" onClick={handleConfirmRemoval}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
}

function SelecionarPerguntas({
  perguntas,
  perguntasSelecionadas,
  onPerguntasSelection,
}: SelecionarPerguntasProps) {
  const tableColumns = useMemo(
    () => [
      columnHelper.accessor((pergunta) => pergunta.titulo, {
        id: 'titulo',
        header: 'Pergunta',
        cell: ({ row }) => {
          return <CheckboxCell row={row} />;
        },
      }),
    ],
    [],
  );

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'titulo', desc: false },
  ]);

  const tablePerguntas = useReactTable({
    data: perguntas,
    columns: tableColumns,
    state: {
      sorting,
      rowSelection: perguntasSelecionadas,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (originalRow) => originalRow.id.toString(),
    onSortingChange: setSorting,
    onRowSelectionChange: (updaterFn) => {
      const newValue = functionalUpdate(updaterFn, perguntasSelecionadas);
      onPerguntasSelection(newValue);
    },
    enableSortingRemoval: false,
    enableRowSelection: ({ id }) => {
      const limiteSelecao = 2;
      const qtdPerguntasSelecionadas = Object.keys(
        perguntasSelecionadas,
      ).length;

      if (qtdPerguntasSelecionadas < limiteSelecao) {
        return true;
      }
      if (qtdPerguntasSelecionadas === limiteSelecao) {
        return id in perguntasSelecionadas;
      }

      return false;
    },
  });

  const tablePerguntasRows = tablePerguntas.getRowModel().rows;

  return (
    <>
      <CategorizacaoScreenContainer
        titulo="Análise de respostas abertas com IA"
        descricao="Selecione até duas perguntas para atribuir categorias."
      >
        <div>
          <b className="text-primary">
            {Object.keys(perguntasSelecionadas).length}
          </b>
          /2 perguntas selecionadas
        </div>
        <Row tag="table" className="pt-3">
          <Col md="12" tag="thead">
            <TableHeader
              headerGroup={tablePerguntas.getHeaderGroups()[0]}
              getColProps={getColumnHeaderProps}
            />
          </Col>

          <Col md="12" tag="tbody">
            {tablePerguntasRows.map((row) => (
              <ListGroupItem
                className={`${styles.tableRow} ${
                  row.getIsSelected() ? styles.selectedRow : ''
                }`}
                key={row.id}
                tag="tr"
              >
                <Row tag="td">
                  {row.getVisibleCells().map((cell) => (
                    <Fragment key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Fragment>
                  ))}
                </Row>
              </ListGroupItem>
            ))}
          </Col>
        </Row>
      </CategorizacaoScreenContainer>
    </>
  );
}

export default SelecionarPerguntas;
