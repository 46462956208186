import TipoVisibilidadePesquisaEnum from 'enums/TipoVisibilidadePesquisaEnum';
import instance from './instance';
import { IPesquisa } from './types';

interface IEditarRestricaoVisualizacaoPesquisaData {
  data: {
    msg: string;
    success: boolean;
    pesquisa: IPesquisa[];
  };
}

const editarRestricaoVisualizacaoPesquisa = async (body: {
  pesquisa_id: number;
  visibilidade: TipoVisibilidadePesquisaEnum;
  usuarios_visualizacao_restrita: number[];
}): Promise<IEditarRestricaoVisualizacaoPesquisaData> =>
  instance.post('/visibilidade/editar', body);

export default editarRestricaoVisualizacaoPesquisa;
